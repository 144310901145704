import * as React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { GeoFenceItem } from "@/data";
import { Icon } from "@iconify/react";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../services/firebase";
import { LoadingButton } from "@mui/lab";
import PolygonArea from "./polygon-area";

import { useState } from "react";

import CircleArea from "./circle-area";

const RenderGeofenceItem = ({
  item,
  index,
  setItem,
}: {
  index: number;
  item: GeoFenceItem;
  setItem: (index: number, item: GeoFenceItem) => void;
}) => {
  const [openMap, setOpenMap] = useState(false);
  const theme = useTheme();
  return (
    <div
      key={index}
      className="set-map-form"
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 16,
        padding: 8,
        alignItems: "center",
        border: "1px solid grey",
        borderRadius: 20,
      }}
    >
      <h4 style={{ marginLeft: 16 }}>A {index + 1}</h4>

      <Box
        display={"flex"}
        // alignItems={"center"}
        // justifyContent={"flex-start"}
        flexGrow={1}
        paddingLeft={4}
        flexDirection={"column"}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          flexGrow={1}
          // paddingLeft={4}
        >
          <Typography>Alert when detected the device is &nbsp; </Typography>

          <Select
            value={item?.direction}
            onChange={event =>
              setItem(index, {
                ...item,
                direction: event.target.value as number,
              })
            }
          >
            <MenuItem value="0">OUT OF</MenuItem>
            <MenuItem value="1">IN</MenuItem>
          </Select>
          <Typography>&nbsp; the &nbsp;</Typography>
          <Select
            value={item?.type}
            onChange={event =>
              setItem(index, {
                ...item,
                type: event.target.value as "circle" | "polygon",
              })
            }
          >
            <MenuItem value="circle">Circle</MenuItem>
            <MenuItem value="polygon">Polygon</MenuItem>
          </Select>
          <Typography> &nbsp;area </Typography>

          <Button onClick={() => setOpenMap(true)}>
            <Icon icon={"basil:edit-outline"} fontSize={20} />
          </Button>
        </Box>
        {item.type === "circle" && item.points.length > 0 && (
          <Typography color={theme.palette.info.main}>
            center: {item.points[0].lat},{item.points[0].lng}
            &nbsp;&nbsp; radius: {item.radius | 0} meters
          </Typography>
        )}
        {item.type === "polygon" && item.points.length > 0 && (
          <Typography color={theme.palette.info.main}>
            A polygon with {item.points.length} paths
          </Typography>
        )}
      </Box>
      <FormControlLabel
        control={
          <Switch
            checked={item?.enable}
            onChange={event =>
              setItem(index, { ...item, enable: event.target.checked })
            }
          />
        }
        label="Enable"
      />
      <Dialog
        open={openMap}
        onClose={() => setOpenMap(false)}
        fullWidth={true}
        maxWidth="xl"
      >
        <DialogTitle>Settings</DialogTitle>
        <DialogContent>
          {item.type === "polygon" && (
            <PolygonArea
              zoom={10}
              path={item.points}
              setPath={(path: { lat: number; lng: number }[]) => {
                setItem(index, { ...item, points: path });
              }}
              handleClose={() => setOpenMap(false)}
            />
          )}
          {item.type === "circle" && (
            <CircleArea
              zoom={10}
              center={item.points[0] || undefined}
              radius={item.radius}
              setCircle={(payload: {
                radius: number;
                center: { lat: number; lng: number };
              }) => {
                setItem(index, {
                  ...item,
                  points: [payload.center],
                  radius: payload.radius,
                });
                console.log(item);
              }}
              handleClose={() => setOpenMap(false)}
            />
          )}
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

const GeoFenceSettings = ({
  items,
  deviceId,
}: {
  items: GeoFenceItem[] | undefined;
  deviceId: string | undefined;
}) => {
  //const navigate = useNavigate();
  const [geofences, setGeofences] = React.useState<GeoFenceItem[]>(
    items ? Object.values(items) : []
  );

  const [saving, setSaving] = React.useState(false);

  const addItem = (
    item: GeoFenceItem = {
      points: [],
      radius: 0,
      direction: 0,
      type: "circle",
      enable: true,
    }
  ) => {
    setGeofences([...geofences, item]);
  };

  const setItem = (index: number, item: GeoFenceItem) => {
    setGeofences([
      ...geofences.slice(0, index),
      item,
      ...geofences.slice(index + 1),
    ]);
  };

  const save = async () => {
    const payload = geofences.filter(item => item.points.length > 0);
    if (payload.length <= 0) {
      return;
    }

    setSaving(true);
    try {
      const tasksRef = doc(db, "tasks", deviceId!);
      await setDoc(tasksRef, {
        device_id: deviceId,
        type: "geo_fence_setting",
        payload,
      });
    } finally {
      setSaving(false);
    }
  };

  React.useEffect(() => {
    if (geofences.length <= 0) {
      addItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <h4 style={{ marginTop: 0 }}>Set Geo Fences</h4>
      <>
        {geofences?.map((item, i) => {
          return (
            <RenderGeofenceItem
              item={item}
              index={i}
              key={i}
              setItem={setItem}
            />
          );
        })}
      </>
      {geofences.length < 4 && (
        <Button size="large" fullWidth onClick={() => addItem()}>
          <Icon icon={"carbon:add-filled"} fontSize={32} />
        </Button>
      )}
      <Box textAlign={"right"}>
        <LoadingButton
          loading={saving}
          variant="outlined"
          size="large"
          onClick={() => save()}
        >
          Save
        </LoadingButton>
      </Box>
    </div>
  );
};

export default GeoFenceSettings;
