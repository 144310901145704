import { MotionAlertSetting } from "../../data";
import { db } from "../../services/firebase";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import { useState } from "react";

const MotionAlertSettings = ({
  setting,
  deviceId,
}: {
  setting: MotionAlertSetting | undefined;
  deviceId: string;
}) => {
  const [data, setData] = useState(
    setting || {
      enable: true,
      duration: 180,
      dial: false,
    }
  );

  const [saving, setSaving] = useState(false);
  const save = async () => {
    data.duration < 180 && setData({ ...data, duration: 180 });
    data.duration > 300 && setData({ ...data, duration: 300 });
    const payload = { ...data };

    setSaving(true);

    try {
      const tasksRef = doc(db, "tasks", deviceId!);
      await setDoc(tasksRef, {
        device_id: deviceId,
        type: "motion_alert",
        payload,
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <h4>
        Set Motion Alert Settings
        <Typography variant="caption" display="block">
          Continuous-moving period to activate the alert, 180-300 seconds
        </Typography>
      </h4>
      <FormControlLabel
        control={
          <Switch
            checked={data?.enable}
            onChange={ev => setData({ ...data, enable: ev.target.checked })}
          />
        }
        label="Enable"
      />
      <TextField
        autoFocus
        margin="dense"
        type="number"
        label="Duration"
        placeholder=""
        variant="standard"
        value={data?.duration}
        onChange={ev => {
          setData({ ...data, duration: parseInt(ev.target.value) });
        }}
      />
      <div style={{ marginLeft: 16 }}>
        <FormControlLabel
          value="Recieve Call"
          control={
            <Checkbox
              checked={data.dial}
              onChange={ev => {
                setData({
                  ...data,
                  dial: ev.target.checked,
                });
              }}
            />
          }
          label="Recieve Call"
          labelPlacement="end"
        />
      </div>
      <Box textAlign={"right"}>
        <LoadingButton
          loading={saving}
          variant="outlined"
          size="large"
          onClick={() => save()}
        >
          Save
        </LoadingButton>
      </Box>
    </>
  );
};

export default MotionAlertSettings;
