import React from "react";

import { Navigate } from "react-router-dom";


import { useAuth } from "../context/auth-context";
 const PublicRoute = ({ children }) => {
    const {isSignedIn} = useAuth()
    if (isSignedIn) {
      return <Navigate to="/dashboard" />;
    }
    return children;
  };
export default PublicRoute;
