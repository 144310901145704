import { saveAs } from "file-saver";
import { db } from "../../services/firebase";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import {
  QuerySnapshot,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  Document,
  Page,
  Text,
  View,
  pdf,
  StyleSheet,
} from "@react-pdf/renderer";
import { User } from "firebase/auth";

const styles = StyleSheet.create({
  page: {
    padding: 32,
  },
  title: {
    fontSize: 20,
    marginBottom: 16,
    textAlign: "center",
  },
  section: {
    marginTop: 10,
    flexGrow: 1,
  },
  row: {
    flexDirection: "row",
    border: "1pt solid black",
    padding: 5,
  },
  cell: {
    fontSize: 10,
    flex: 1,
    width: "45%",
    padding: 5,
  },
  cellHeader: {
    fontSize: 12,
    flex: 1,
    padding: 5,
  },
});

interface Props {
  user: User | undefined;
}

const Reports = ({ user }: Props) => {
  const { deviceId } = useParams();
  const [deviceType, setDeviceType] = useState<number>();
  const [selectedOption, setSelectedOption] = useState<String>("last24Hours");
  const [alertData, setAlertData] = useState<any[]>([]);
  const [locationData, setLocationData] = useState<any[]>([]);
  const [heartRateData, setHeartRateData] = useState<any[]>([]);
  const [stepsData, setStepsData] = useState<any[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);
    setSelectedOption(event.target.value);
  };

  const formatTimestamp = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString();
  };

  const fetchDailyAlerts = async () => {
    let hours = 0;
    if (selectedOption === "last12Hours") {
      hours = 12;
    }
    if (selectedOption === "last24Hours") {
      hours = 24;
    }

    const now = new Date();
    const timeInterval = new Date(now.getTime() - hours * 60 * 60 * 1000);
    const alertsCollection = collection(db, `/alert/${deviceId}/records`);
    const alertsQuery = query(
      alertsCollection,
      where("timestamp", ">", Math.trunc(timeInterval.getTime() / 1000)),
      orderBy("timestamp", "desc")
    );

    const querySnapshot: QuerySnapshot = await getDocs(alertsQuery);

    const alerts = querySnapshot.docs.map((doc) => doc.data());

    setAlertData(alerts);
  };

  const fetchLastKnownLocation = async () => {
    let hours = 0;
    if (selectedOption === "last12Hours") {
      hours = 12;
    }
    if (selectedOption === "last24Hours") {
      hours = 24;
    }

    const now = new Date();
    const timeInterval = new Date(now.getTime() - hours * 60 * 60 * 1000);
    const locationTrackingCollection = collection(
      db,
      `/location-tracking/${deviceId}/tracks`
    );
    const locationTrackingQuery = query(
      locationTrackingCollection,
      // where("timestamp", ">", Math.trunc(timeInterval.getTime() / 1000)),
      orderBy("timestamp", "desc"),
      limit(1)
    );

    const querySnapshot: QuerySnapshot = await getDocs(locationTrackingQuery);

    const locationTrackingData = querySnapshot.docs.map((doc) => doc.data());

    setLocationData(locationTrackingData);
  };

  const fetchHealthData = async () => {
    const heartRateCollection = collection(
      db,
      `/health/${deviceId}/heart-rate`
    );
    const stepsCollection = collection(db, `/health/${deviceId}/steps`);

    const heartRateQuery = query(
      heartRateCollection,
      // where("timestamp", ">", Math.trunc(timeInterval.getTime() / 1000)),
      orderBy("timestamp", "desc"),
      limit(3)
    );

    const stepsQuery = query(
      stepsCollection,
      // where("timestamp", ">", Math.trunc(timeInterval.getTime() / 1000)),
      orderBy("timestamp", "desc"),
      limit(1)
    );

    const heartRateSnapShot: QuerySnapshot = await getDocs(heartRateQuery);
    const stepsSnapShot: QuerySnapshot = await getDocs(stepsQuery);

    const heartRate = heartRateSnapShot.docs.map((doc) => doc.data());
    setHeartRateData(heartRate);

    const steps = stepsSnapShot.docs.map((doc) => doc.data());
    setStepsData(steps);
  };

  const generateReport = () => {
    // console.log("Clicked" + deviceType);
    // console.log("Alerts", alertData);
    // console.log("location data", locationData);
    // console.log("heartRate data", heartRateData);
    // console.log("steps data", stepsData);
    const pdfDoc = (
      <Document>
        <Page style={styles.page}>
          <Text style={styles.title}>User Report</Text>
          <View style={styles.section}>
            <Text style={{ fontSize: 14, marginBottom: 12 }}>
              Alerts Occured in Last 24 Hours.
            </Text>
            <View style={styles.row}>
              <Text style={styles.cellHeader}>Alert Type</Text>
              <Text style={styles.cellHeader}>Date and Time</Text>
            </View>
            {alertData.map((alert, index) => (
              <View style={styles.row} key={index}>
                <Text style={styles.cell}>{alert.type}</Text>
                <Text style={styles.cell}>
                  {formatTimestamp(alert.timestamp)}
                </Text>
              </View>
            ))}
          </View>
          <View style={styles.section}>
            <Text style={{ fontSize: 14, marginBottom: 12 }}>
              Last Known Location Of Device
            </Text>
            <View style={styles.row}>
              <Text style={styles.cellHeader}>Address</Text>
              <Text style={styles.cellHeader}>Date and Time</Text>
            </View>
            {locationData.map((data, index) => (
              <View style={styles.row} key={index}>
                <Text style={styles.cell}>{data.address}</Text>
                <Text style={styles.cell}>
                  {formatTimestamp(data.timestamp)}
                </Text>
              </View>
            ))}
          </View>
          {/* heart rate section */}
          {(deviceType == 5) && (
            <View style={styles.section}>
              <Text style={{ fontSize: 14, marginBottom: 12 }}>Heart Rate</Text>
              <View style={styles.row}>
                <Text style={styles.cellHeader}>HeartBeat</Text>
                <Text style={styles.cellHeader}>Date and Time</Text>
              </View>
              {heartRateData.map((data, index) => (
                <View style={styles.row} key={index}>
                  <Text style={styles.cell}>{data.value}</Text>
                  <Text style={styles.cell}>
                    {formatTimestamp(data.timestamp)}
                  </Text>
                </View>
              ))}
            </View>
          )}
          {(deviceType == 5) && (
            <View style={styles.section}>
              <Text style={{ fontSize: 14, marginBottom: 12 }}>Steps</Text>
              <View style={styles.row}>
                <Text style={styles.cellHeader}>Steps</Text>
                <Text style={styles.cellHeader}>Date and Time</Text>
              </View>
              {stepsData.map((data, index) => (
                <View style={styles.row} key={index}>
                  <Text style={styles.cell}>{data.value}</Text>
                  <Text style={styles.cell}>
                    {formatTimestamp(data.timestamp)}
                  </Text>
                </View>
              ))}
            </View>
          )}
        </Page>
      </Document>
    );

    const asBlob = pdf(pdfDoc).toBlob();
    asBlob.then((blob) => {
      const date = new Date();
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Note: Months are zero-based
      const year = String(date.getFullYear()).slice(-2); // Use the last two digits of the year
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      saveAs(blob, `report-${day}${month}${year}${hours}${minutes}.pdf`);
    });
  };

  useEffect(() => {
    // get device type
    const usersCollection = collection(db, "users");
    let userEmail: String = "";
    if (user?.email) {
      userEmail = user.email;
    }
    const q = query(usersCollection, where("email", "==", userEmail));
    getDocs(q).then((data) => {
      data.docChanges().forEach((item) => {
        const userDevices = item.doc.data().devices;
        userDevices.map((device: any) => {
          if (device.id === deviceId) {
            if (device.model === "AW04") {
              setDeviceType(4);
            }
            if (device.model === "AW05") {
              setDeviceType(5);
            }
          }
        });
      });
    });

    fetchDailyAlerts();
    fetchLastKnownLocation();
    fetchHealthData();
  }, [deviceId, selectedOption, user?.email]);

  return (
    <Box className="w-full ">
      <Card>
        <CardContent>
          <Typography variant="h6">Generate Reports</Typography>
          <RadioGroup
            aria-labelledby="radio-button-group-lable"
            defaultValue="last24Hours"
            sx={{ marginTop: 2 }}
            name="radio-buttons-group"
          >
            {/* <FormControlLabel
              value="last12Hours"
              control={<Radio onChange={handleChange} />}
              label="Last 12 Hours"
            /> */}
            <FormControlLabel
              value="last24Hours"
              control={<Radio onChange={handleChange} />}
              label="Last 24 Hours"
            />
          </RadioGroup>
          <Button
            sx={{ marginTop: 2 }}
            variant="outlined"
            onClick={() => {
              generateReport();
            }}
          >
            {"Download"}
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Reports;
