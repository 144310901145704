import { Card, Typography, CardHeader, CardContent } from "@mui/material";
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
} from "@mui/lab";
// utils
import { fDateTime, fToNow } from "../../utils/formatTime";
import AlterFilterDialog from "./alert-filter";
import { Alert } from "../../data";

type Props = {
  list: Alert[];
  setAlertsFilter: (keys: string[]) => void;
};

const AlertList = ({ list, setAlertsFilter, ...other }: Props) => {
  return (
    <Card {...other}>
      <CardHeader
        title={
          <>
            Alerts
            <AlterFilterDialog setAlertsFilter={setAlertsFilter} />
          </>
        }
      />

      <CardContent
        sx={{
          "& .MuiTimelineItem-missingOppositeContent:before": {
            display: "none",
          },

          height: "50rem",
          overflowY: "scroll",
        }}
      >
        <Timeline>
          {list.map((item, index) => (
            // <OrderItem
            //   key={item.id}
            //   item={item}
            //   isLast={index === list.length - 1}
            // />
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineDot color={"error"} />
              </TimelineSeparator>

              <TimelineContent>
                <Typography variant="subtitle2">
                  {item.type}
                  <Typography variant="caption">
                    {" "}
                    ({fToNow(item.timestamp * 1000)})
                  </Typography>
                </Typography>

                <Typography variant="caption" sx={{ color: "text.secondary" }}>
                  {fDateTime(item.timestamp * 1000)}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </CardContent>
    </Card>
  );
};

export default AlertList;
