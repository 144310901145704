import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
} from "@mui/material";
import { useState } from "react";

type Props = {
  setAlertsFilter: (keys: string[]) => void;
};

const AlterFilterDialog = ({ setAlertsFilter }: Props) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    sos: false,
    no_motion: false,
    out_fence: false,
    fall_down: false,
    battery_low_power: false,
  });
  const { sos, no_motion, out_fence, fall_down, battery_low_power } = state;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
  const handleSave = () => {
    const keys = Object.keys(state) as (keyof typeof state)[];

    const filterKeys = keys.filter(function (key) {
      return state[key];
    });

    setAlertsFilter(filterKeys);
    setOpen(false);
  };
  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <Icon icon={"mdi:filter"} />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true}>
        <DialogTitle>Alerts filter</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>comstomize your device</DialogContentText> */}
          <Box sx={{ display: "flex" }}>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="sos"
                      checked={sos}
                      onChange={handleChange}
                    />
                  }
                  label="SOS"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="no_motion"
                      checked={no_motion}
                      onChange={handleChange}
                    />
                  }
                  label="No Motion"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="out_fence"
                      checked={out_fence}
                      onChange={handleChange}
                    />
                  }
                  label="Out Fence"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="fall_down"
                      checked={fall_down}
                      onChange={handleChange}
                    />
                  }
                  label="Fall Down"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="battery_low_power"
                      checked={battery_low_power}
                      onChange={handleChange}
                    />
                  }
                  label="Battery Low Power"
                />
              </FormGroup>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleSave}>Filter</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AlterFilterDialog;
