import * as React from "react";

import { Routes, Route } from "react-router-dom";

import { ContextProvider as AuthContextProvider } from "./context/auth-context";

import Login from "./pages/login";
import Register from "./pages/register";
import Dashboard from "./pages/dashboard";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./index.css";

import PublicRoute from "./protactroute/PublicRoute";
import PrivateRoute from "./protactroute/PrivateRoute";
import Settings from "./pages/settings-page/index";

export default function App() {
  return (
    <>
      <AuthContextProvider>
        <Routes>
          {/* <Route path="/" element={<Index />} /> */}
          <Route
            path="/"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/dashboard/:deviceId?/:tab?"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/signup"
            element={
              <PublicRoute>
                <Register />
              </PublicRoute>
            }
          />
          <Route path="/settings/:deviceId" element={<Settings />} />
        </Routes>
      </AuthContextProvider>
      <ToastContainer theme="dark" />
    </>
  );
}
