import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { Libraries } from "@react-google-maps/api";
import {
  GoogleMap,
  useJsApiLoader,
  Polyline,
  OverlayView,
} from "@react-google-maps/api";

type MapProps = {
  center: { lat: number; lng: number };
  zoom: number;
  locations: { lat: number; lng: number }[];
};

const mapLibraries: Libraries = ["drawing", "places"];

const MapComponent = (props: MapProps) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API as string,
    libraries: mapLibraries,
    version: "weekly",
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ width: "100%", height: "400px" }}
      zoom={props.zoom}
      center={props.center}
      options={{
        streetViewControl: false,
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
        },
      }}
      // onLoad={onLoaded}
    >
      <Polyline
        path={props.locations}
        //onLoad={onLoaded}
        options={{
          strokeColor: "#FF0000",
          strokeWeight: 2,
          strokeOpacity: 1.0,
        }}
      />
      <OverlayView
        position={props.center}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <Box
          component="div"
          border={2}
          // borderColor="white"
          bgcolor="white"
          boxShadow={2}
          display="flex"
          borderRadius="50%"
          alignItems="center"
          justifyContent="center"
          width={28}
          height={28}
        >
          <Icon icon={"mdi:watch"} width={20} height={20} />
        </Box>
      </OverlayView>
    </GoogleMap>
  ) : (
    <LoadingButton loading={true} className="w-full h-20"></LoadingButton>
  );
};

export default MapComponent;
