import { Icon } from "@iconify/react";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import { DeviceStatus } from "../../data";
import { db } from "../../services/firebase";
import { collection, doc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { useParams } from "react-router";
import { User } from "firebase/auth";

interface Props {
  user: User | undefined,
}


const BaseInfo = ({ user }: Props) => {
  const { deviceId } = useParams();

  const [status, setStatus] = useState<DeviceStatus>();
  const [deviceType, setDeviceType] = useState<String>();

  useEffect(() => {
    if (!deviceId) return;

    setStatus(undefined);

    const usersCollection = collection(db, 'users');
    let userEmail: String = '';
    if (user?.email) {
      userEmail = user.email;
    }
    const q = query(usersCollection, where('email','==', userEmail));
    getDocs(q).then((data) => {
      data.docChanges().forEach((item) => {
        const userDevices = item.doc.data().devices;
        userDevices.map((device: any) => {
          if (device.id === deviceId) {
            if(device.model === 'AW04') {
              setDeviceType('4');
            }
            if (device.model === 'AW05') {
              setDeviceType('5');
            }
          }
        });
      })
    })


    const statusRef = doc(db, `device-status`, deviceId);
    const unsubStatus = onSnapshot(statusRef, querySnapshot => {
      if (querySnapshot.exists()) {
        setStatus(querySnapshot.data() as DeviceStatus);
      } else {
        setStatus(undefined);
      }
    });

    return () => {
      unsubStatus();
    };
  }, [deviceId, user?.email]);

  if (!deviceId) {
    return <></>;
  }
  return (
    <Grid container spacing={4}> 
    <Grid item xs={12} sm={6} md={5}>
      <Card>
        <CardContent>
          <Stack direction="row" alignItems="center">
          <Typography variant="h6">Device Info:</Typography>
          <Typography variant="h6" flexGrow={1} align="right">{deviceId}</Typography>
          </Stack>
          
          <Divider sx={{ borderStyle: "dashed", marginY: 2 }} />
          <Stack spacing={1} direction="column" paddingBottom={"0px"}>
            <Stack spacing={1} direction="row" alignItems="center">
              <Icon icon={"mdi:account"} />
              <Typography noWrap>User name:</Typography>
              <Typography flexGrow={1} align='right'>
                {user?.email ? user.email : '-'}
              </Typography>
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center">
              <Icon icon={"mdi:cellphone-link"} />
              <Typography noWrap>Device type:</Typography>
              <Typography flexGrow={1} align='right'>
                {deviceType ? deviceType : "-"}
              </Typography>
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center">
              <Icon icon={"mdi:battery"} />
              <Typography noWrap>Battery:</Typography>
              <Typography flexGrow={1} align='right'>
                {status?.battery_level ? status.battery_level + "%" : "-"}
              </Typography>
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center">
              <Icon icon={"material-symbols:cell-tower"} />
              <Typography noWrap>Cellular Mode:</Typography>
              <Typography flexGrow={1} align='right'>4G</Typography>
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center">
              <Icon icon={"ic:sharp-gps-fixed"} />
              <Typography noWrap>GPS:</Typography>
              <Typography flexGrow={1} align='right'>
                {status?.gps ? "Enabled" : "Disabled"}
              </Typography>
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center">
              <Icon icon={"mdi:motion"} />
              <Typography noWrap>Motion:</Typography>
              <Typography align='right' flexGrow={1}>{status?.motion ? "Yes" : "No"}</Typography>
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center">
              <Icon icon={"fa6-solid:person-falling"} />
              <Typography noWrap>Fall Detect:</Typography>
              <Typography align='right' flexGrow={1}>Enabled</Typography>
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center">
              <Icon icon={"carbon:signal-strength"} />
              <Typography noWrap>Signal Strength:</Typography>
              <Typography align='right' flexGrow={1}>{status?.signal_strength}</Typography>
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center">
              <Icon icon={"mdi:battery-charging"} />
              <Typography noWrap>In Charging:</Typography>
              <Typography align='right' flexGrow={1}>
                {status?.in_charging ? "Yes" : "No"}
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
    </Grid>
  );
};
export default BaseInfo;
