import { useEffect, useState } from "react";
import { Location } from "../../data";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useParams } from "react-router";
import { db } from "../../services/firebase";
import { Box, Button, ButtonGroup, Grid, Typography } from "@mui/material";
import LocationList from "./location-list";
import MapComponent from "./map";
import moment from "moment";

const GpsTracking = () => {
  const [locations, setLocations] = useState<Location[]>([]);
  const [center, setCenter] = useState<Location>();
  const { deviceId } = useParams();

  const [dateRange, setDateRange] = useState<[from: number, to: number]>();

  const handleSetRange = (val: string) => {
    if (val === "last_week") {
      const start = moment().subtract(1, "weeks").startOf("week");
      const end = moment().subtract(1, "weeks").endOf("week");
      setDateRange([start.unix(), end.unix()]);
    } else if (val === "last_month") {
      const start = moment().subtract(1, "months").startOf("month");
      const end = moment().subtract(1, "months").endOf("month");
      setDateRange([start.unix(), end.unix()]);
    }
  };

  useEffect(() => {
    if (!deviceId) return;
    setLocations([]);
    // console.log("changed");
    const conditions = [];
    if (dateRange) {
      conditions.push(where("timestamp", ">", dateRange[0]));
      conditions.push(where("timestamp", "<=", dateRange[1]));
    }

    const locationQuery = query(
      collection(db, `/location-tracking/${deviceId}/tracks`),
      orderBy("timestamp", "desc"),
      //where("timestamp", ">", Date.now() / 1000 - 864000 * 10)
      ...conditions
    );

    const unsubLocations = onSnapshot(locationQuery, snapshot => {
      const locationsData: Location[] = [];
      snapshot.forEach(doc => {
        locationsData.push({ id: doc.id, ...doc.data() } as Location);
      });
      setLocations(locationsData);
    });

    return () => {
      unsubLocations();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId, dateRange]);

  useEffect(() => {
    if (locations.length > 0) {
      setCenter(locations[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  return (
    <Box className="w-full ">
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={8} sx={{ minHeight: "30rem" }}>
          {center?.latitude && center?.longitude && (
            <MapComponent
              zoom={15}
              center={{ lat: center!.latitude, lng: center!.longitude }}
              locations={locations.map(l => {
                return { lat: l.latitude, lng: l.longitude };
              })}
            />
          )}

          <Box className="mt-8 flex flex-col">
            <Typography>Time Range:</Typography>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                onClick={() =>
                  setDateRange([
                    Date.now() / 1000 - 3600 * 24,
                    Date.now() / 1000,
                  ])
                }
              >
                Last 24 Hours
              </Button>
              <Button
                onClick={() =>
                  setDateRange([
                    Date.now() / 1000 - 3600 * 24 * 3,
                    Date.now() / 1000,
                  ])
                }
              >
                Last 3 Days
              </Button>
              <Button onClick={() => handleSetRange("last_week")}>
                Last Week
              </Button>
              <Button onClick={() => handleSetRange("last_month")}>
                Last Month
              </Button>
            </ButtonGroup>
            {dateRange && (
              <Box>
                <span className="text-gray-500 text-sm"> From: </span>
                {moment(dateRange[0] * 1000).format("ll")}
                <span className="text-gray-500 text-sm"> To: </span>
                {moment(dateRange[1] * 1000).format("ll")}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <LocationList
            list={locations.filter(l => !!l.address).slice(0, 100)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GpsTracking;
