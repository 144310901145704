import { db } from "../../services/firebase";
import { LoadingButton } from "@mui/lab";
import { Box, TextField, Typography } from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import { useState } from "react";

const SetEmails = ({
  emails: _emails,
  deviceId,
}: {
  emails: string[];
  deviceId: string;
}) => {
  const [emails, setEmails] = useState(_emails.join(";"));
  const [saving, setSaving] = useState(false);

  const save = async () => {
    setSaving(true);
    try {
      const tasksRef = doc(db, "device-setting", deviceId!);
      await setDoc(
        tasksRef,
        {
          sos_emails: emails && emails.split(";"),
        },
        { merge: true }
      );
    } finally {
      setSaving(false);
    }
  };
  return (
    <>
      <h4>Set SOS Emails</h4>
      <TextField
        autoFocus
        margin="dense"
        id="emails"
        value={emails}
        onChange={e => setEmails(e.target.value)}
        label="Add Email to get notifications"
        type="email"
        fullWidth
        variant="standard"
        helperText={
          <>
            Multiple emails seperated by &nbsp;
            <Typography
              component={"span"}
              variant={"body2"}
              display={"inline"}
              color={"red"}
            >
              ;
            </Typography>
          </>
        }
      />
      <Box textAlign={"right"}>
        <LoadingButton
          loading={saving}
          variant="outlined"
          size="large"
          onClick={() => save()}
        >
          Save
        </LoadingButton>
      </Box>
    </>
  );
};

export default SetEmails;
