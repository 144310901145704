import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Circle,
  DrawingManager,
  Libraries,
  Autocomplete,
} from "@react-google-maps/api";
import { Box, Button } from "@mui/material";

type Latlng = { lat: number; lng: number };
type PProps = {
  zoom: number;
  center?: { lat: number; lng: number };
  radius?: number;

  setCircle: (data: { center: Latlng; radius: number }) => void;
  handleClose: () => void;
};

const mapLibraries = ["drawing", "places"];

function CircleArea({
  center,
  setCircle,
  radius,
  zoom = 15,
  handleClose,
}: PProps) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API as string,
    libraries: mapLibraries as Libraries,
    version: "weekly",
  });
  const [map, setMap] = useState<google.maps.Map>();
  const [circleData, setCircleData] = useState<{
    center: Latlng | undefined;
    radius: number | undefined;
  }>({ center, radius });

  // get user's geo
  useEffect(() => {
    if (center) return;
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        console.log("set user location center");
        map?.setCenter(pos);
        map?.setZoom(12);
      },
      () => {
        console.log("set default center");
        map?.setCenter({ lat: 35.0, lng: -98.0 });
        map?.setZoom(5);
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  const circleRef = useRef<google.maps.Circle>();
  const listenersRef = useRef<google.maps.MapsEventListener[]>([]);
  // const [path, setPath] = useState<{ lat: number; lng: number }[]>(
  //   initPath || []
  // );

  const onEdit = useCallback(() => {
    if (circleRef.current) {
      const _center = circleRef.current.getCenter();
      setCircle({
        center: { lat: _center!.lat(), lng: _center!.lng() },
        radius: circleRef.current.getRadius() | 0,
      });
    }
  }, [setCircle]);

  // Bind refs to current Polygon and listeners
  const onCircleLoad = useCallback(
    (circle: google.maps.Circle) => {
      circleRef.current = circle;
      // const path = polygon.getPath();
      listenersRef.current.push(
        circle.addListener("set_at", onEdit),
        circle.addListener("insert_at", onEdit),
        circle.addListener("remove_at", onEdit)
      );
      if (center) {
        const map = circleRef.current.getMap();
        map?.fitBounds(circleRef.current.getBounds()!);
      }
    },
    [onEdit]
  );

  // Clean up refs
  const onCircleUnmount = useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove());
    circleRef.current = undefined;
  }, []);

  //=========search box
  const [searchBox, setSearchBox] = useState<google.maps.places.Autocomplete>();
  // const [searchResult, setSearchResult] = useState<any[]>([]);
  const searchBoxOnload = (ref: google.maps.places.Autocomplete) => {
    setSearchBox(ref);
    // setSearchResult(ref as any);
  };
  const onPlaceChanged = () => {
    if (searchBox != null) {
      const place = searchBox.getPlace();
      if (place.geometry?.viewport) {
        map!.fitBounds(place.geometry.viewport);
      } else if (place.geometry?.location) {
        map!.setCenter(place.geometry.location);
        map!.setZoom(15);
      }
    }
  };

  return isLoaded ? (
    <Box>
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "400px" }}
        zoom={zoom}
        center={center}
        onLoad={map => setMap(map)}
        options={{
          streetViewControl: false,
          fullscreenControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM,
          },
        }}
      >
        <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={searchBoxOnload}>
          <input
            type="text"
            placeholder="Where you wanna pinpoint a geo-fence?"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          />
        </Autocomplete>
        {circleData?.center && circleData?.radius && (
          <Circle
            // Make the Polygon editable / draggable
            editable
            draggable
            center={circleData.center}
            radius={circleData.radius}
            // Event used when manipulating and adding points
            onMouseUp={onEdit}
            // Event used when dragging the whole Polygon
            onDragEnd={onEdit}
            onRadiusChanged={onEdit}
            onLoad={onCircleLoad}
            onUnmount={onCircleUnmount}
          />
        )}
        {(!circleData.center || !circleData.radius) && (
          <MyDrawingManager setCircle={setCircleData} />
        )}
      </GoogleMap>
      <Box sx={{ paddingTop: 6 }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={() =>
            setCircleData({ center: undefined, radius: undefined })
          }
        >
          Clear
        </Button>
        <Button
          onClick={() => {
            if (circleData.center && circleData.radius)
              setCircle({
                center: circleData.center!,
                radius: circleData.radius!,
              });
            handleClose();
          }}
        >
          Confirm
        </Button>
      </Box>
    </Box>
  ) : (
    <>Loading...</>
  );
}

const MyDrawingManager = ({
  setCircle,
}: {
  setCircle: (value: { center: Latlng; radius: number }) => void;
}) => {
  const onCircleComplete = (circle: google.maps.Circle) => {
    setCircle({
      center: {
        lat: circle.getCenter()!.lat(),
        lng: circle.getCenter()!.lng(),
      },
      radius: circle.getRadius() | 0,
    });
    circle.setMap(null);
  };

  return (
    <DrawingManager
      options={{
        drawingControl: true,
        drawingControlOptions: {
          drawingModes: [google.maps.drawing.OverlayType.CIRCLE],
        },
      }}
      drawingMode={google.maps.drawing.OverlayType.CIRCLE}
      // onRectangleComplete={onRectangleComplete}
      onCircleComplete={onCircleComplete}
    />
  );
};

export default CircleArea;
