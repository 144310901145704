import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useContext, useState } from "react";
import { MenuItem, Select, Stack } from "@mui/material";
import { toast } from "react-toastify";
import { AuthContext, useAuth } from "../../context/auth-context";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../services/firebase";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router";
import { DeviceInterface } from "@/data";

interface Props {
  open: boolean;
  handleClose: () => void;
}

export default function AddDevice({ open, handleClose }: Props) {
  const [deviceName, setDeviceName] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [deviceModel, setDeviceModel] = useState("");
  // const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const handleSubmit = async () => {
    let hasError = false;
    setLoading(true);
    if (!deviceName) {
      toast.error("Device name cannot be null");
      hasError = true;
    }
    if (!deviceId) {
      toast.error("Device IEMI cannot be null");
      hasError = true;
    }
    if (!deviceModel) {
      toast.error("Device model cannot be null");
      hasError = true;
    }
    
    if(hasError) {
      setLoading(false);
      return;
    }
    try {
      const userEmail = user?.email;
      const userDocRef = doc(db, `users/${userEmail}`);

      getDoc(userDocRef).then((documentSnap) => {
        if (documentSnap.exists()) {
          console.log("users data", documentSnap.data());
          let devices = documentSnap.data().devices;
          const newDevice: DeviceInterface = {
            id: deviceId,
            IMEI: deviceId,
            name: deviceName,
            model: deviceModel,
          };
          devices.push(newDevice);
          updateDoc(userDocRef, { devices: devices })
            .then(() => {
              console.log("Updated Devices data successfuly!");
              toast.success("Bind new device successfully!");
            })
            .catch((e) => {
              console.log("Failed to add device!", e);
              toast.success(e?.message || "Bind new device failed!");
            });
        }
      });
    } catch (e: any) {
      toast.success(e?.message || "Bind new device failed!");
    }
    setLoading(false);
    handleClose();
    navigate(`/dashboard/${deviceId}`);
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Device</DialogTitle>
        <DialogContent sx={{ minWidth: 400 }}>
          <Stack spacing={3}>
            <TextField
              placeholder="Set a special name for recognize your device"
              label="Device Name"
              type="text"
              fullWidth
              variant="standard"
              value={deviceName}
              onChange={e => setDeviceName(e.target.value)}
            />
            <TextField
              label="Device IEMI"
              type="number"
              fullWidth
              variant="standard"
              value={deviceId}
              onChange={e => setDeviceId(e.target.value)}
            />
            <Select
              value={deviceModel}
              onChange={e => setDeviceModel(e.target.value)}
            >
              <MenuItem value="AW04">AW04</MenuItem>
              <MenuItem value="AW05">AW05</MenuItem>
            </Select>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>Cancel</Button>
          <LoadingButton variant="outlined" loading={loading} onClick={handleSubmit}>
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
