import React, {
  useState,
  createContext,
  useEffect,
  ReactNode,
  useContext,
} from "react";
// Firebase auth functions
import {
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  User,
} from "firebase/auth";
import Auth from "../auth/Auth";

// Firebase auth instance
import { db, auth as firebaseAuth } from "../services/firebase";
import { collection, getDocs, query, where, setDoc, doc } from "firebase/firestore";
// Google oauth provider
const provider = new GoogleAuthProvider();
// Contexts
export type AuthContextInterface = {
  isSignedIn: boolean;
  user: User | undefined;
  signIn?: (email: string, password: string) => Promise<string | undefined>;
  signUp?: (email: string, password: string) => Promise<string | undefined>;
  signOut?: () => void;
  googleSignIn?: () => void;
  loading: boolean;
};
export const AuthContext = createContext<AuthContextInterface>({
  isSignedIn: false,
  user: undefined,
  loading: false,
  // signIn: () => {},
  // signUp: () => {},
  // signOut: () => {},
});

interface AuthContextProps {
  children: unknown;
}

export const ContextProvider = ({ children }: AuthContextProps) => {
  // States to check auth status
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Listener updates auth status when detects change
    onAuthStateChanged(firebaseAuth, user => {
      if (user) {
        user.getIdToken().then(token => {
          Auth.login(token);
        });
        setIsSignedIn(true);
        setUser(user);
      } else {
        setIsSignedIn(false);
        setUser(undefined);
      }
      setLoading(false);
    });
  }, []);

  // Functions handling auth
  const signIn = async (email: string, password: string) => {
    let getResponse: any;
    setLoading(true);
    await signInWithEmailAndPassword(firebaseAuth, email, password).then(
      async function (response) {
        // Added below code in signup instead of signup
        // Ideally this should be done in Sing Up. 
        // But here we need to add exisintg used in new users collection.
        /* const user = response.user;
        console.info('logged in user', user);
        const userEmail = user?.email;
        const usersRef = collection(db,`users`);
        const querySnapshot = await getDocs(query(usersRef, where("email", "==", userEmail)));
        if (querySnapshot.empty) {
          console.info('User not found in DB. creating new user', user);
          await setDoc(doc(db, `users/${userEmail}`), {
            id: user.email,
            email: user.email,
            displayName: user.displayName,
            user_uid: user.uid,
            devices: []
          });
        } else {
          console.info('User found in DB');
          // querySnapshot.forEach((doc) => {
          //   // doc.data() is never undefined for query doc snapshots
          //   console.info(doc.id, " => ", doc.data());
          // });
        } */
        getResponse = response;
      },
      error => {
        getResponse = error;
      }
    );
    setLoading(false);
    return getResponse;
    // try {
    //   await signInWithEmailAndPassword(firebaseAuth, email, password);
    //   return;
    // } catch (err: unknown) {
    //   if (err instanceof Error) {
    //     console.log(err, err.message);
    //     return err.message;
    //   }
    // }
  };
  const signUp = async (email: string, password: string) => {
    let getResponse: any;
    await createUserWithEmailAndPassword(firebaseAuth, email, password).then(
      async function (response) {
        getResponse = response;
        //save user to user's table
        const user = response.user;
        const userEmail = user?.email;
        const usersRef = collection(db,`users`);
        const querySnapshot = await getDocs(query(usersRef, where("email", "==", userEmail)));
        if (querySnapshot.empty) {
          console.info('User not found in DB. creating new user', user);
          await setDoc(doc(db, `users/${userEmail}`), {
            id: user.email,
            email: user.email,
            displayName: user.displayName,
            user_uid: user.uid,
            devices: []
          });
        } else {
          console.info('User found in DB');
          // querySnapshot.forEach((doc) => {
          //   // doc.data() is never undefined for query doc snapshots
          //   console.info(doc.id, " => ", doc.data());
          // });
        }
      },
      error => {
        getResponse = error;
      }
    );
    return getResponse;
    // try {
    //   await createUserWithEmailAndPassword(firebaseAuth, email, password);
    //   return;
    // } catch (err: unknown) {
    //   if (err instanceof Error) {
    //     console.log(err.message);
    //     return err.message;
    //   }
    // }
  };
  const signOut = async () => {
    try {
      await firebaseSignOut(firebaseAuth);
    } catch (err: unknown) {
      if (err instanceof Error) {
        // console.log(err.message);
        return err.message;
      }
    }
  };
  const googleSignIn = async () => {
    try {
      await signInWithPopup(firebaseAuth, provider);
    } catch (err: unknown) {
      if (err instanceof Error) {
        // console.log(err.message);
        return err.message;
      }
    }
  };
  // Context provider
  return (
    <AuthContext.Provider
      value={{
        isSignedIn,
        user,
        signIn,
        signUp,
        signOut,
        googleSignIn,
        loading,
      }}
    >
      {children as ReactNode}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
