import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DashboardLayout from "../../layouts/dashboard";
import { Helmet } from "react-helmet-async";
import { Button, CircularProgress, Container, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import SOSSettings from "./sos-settings";
import GeoFence from "./geo-fence-settings/geofence";
import FallAlertSettings from "./fall-alert-setting";
import NoMotionAlertSettings from "./motion-no-motion-settings";
import { db } from "../../services/firebase";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { SettingsData } from "../../data";
import { Icon } from "@iconify/react";
import MotionAlertSettings from "./motion-alert-settings";
import SetName from "./setname";
import SetEmails from "./setemails";
import GeoFenceSettings from "./geo-fence-settings/geofence";
import { LoadingButton } from "@mui/lab";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ width: "100vw", overflow: "auto" }}
      {...other}
    >
      {value === index && <Box sx={{ p: 2, width: "100%" }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Settings = () => {
  const navigate = useNavigate();

  const { deviceId } = useParams();

  const settingRef = doc(db, `device-setting/${deviceId}`);

  const [settings, setSettings] = React.useState<SettingsData>();
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    onSnapshot(settingRef, snap => {
      setSettings(snap.data() as SettingsData);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout>
      <Helmet>
        <title> Settings </title>
      </Helmet>
      <Container maxWidth="xl">
        <Typography variant="h3" sx={{ paddingBottom: 5 }}>
          <IconButton
            onClick={() => {
              navigate(`/dashboard/${deviceId}`);
            }}
          >
            <Icon icon={"icon-park-outline:left-c"} />
          </IconButton>
          Settings for {settings?.name || deviceId}
          <br />
          {deviceId && <SycnParams deviceId={deviceId} />}
          <br />
          <Typography variant="caption" color="red-100">
            Please make sure that your device is connected to the server before
            setup
          </Typography>
        </Typography>

        {loading && (
          <Box
            height={300}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <SettingContent settings={settings!} deviceId={deviceId!} />
        )}
      </Container>
    </DashboardLayout>
  );
};

const SettingContent = ({
  settings,
  deviceId,
}: {
  settings: SettingsData;
  deviceId: string;
}) => {
  const [value, setValue] = React.useState(0);

  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        height: 480,
      }}
    >
      <Tabs
        orientation="vertical"
        variant="standard"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: "divider", width: 300 }}
      >
        <Tab label="SOS Alert" {...a11yProps(1)} />
        <Tab label="Geo Fence Alert" {...a11yProps(2)} />
        <Tab label="Fall Down Alert" {...a11yProps(3)} />
        <Tab label="Motion Alert" {...a11yProps(4)} />
        <Tab label="No Motion Alert" {...a11yProps(5)} />
        <Tab label="SOS Emails" {...a11yProps(6)} />
      </Tabs>

      <TabPanel value={value} index={0}>
        <SOSSettings items={settings?.contact_numbers} deviceId={deviceId} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <GeoFenceSettings items={settings?.geo_fences} deviceId={deviceId} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        {
          <FallAlertSettings
            setting={settings?.falldown_alert}
            deviceId={deviceId}
          />
        }
      </TabPanel>
      <TabPanel value={value} index={3}>
        {
          <MotionAlertSettings
            setting={settings?.motion_alert}
            deviceId={deviceId}
          />
        }
      </TabPanel>
      <TabPanel value={value} index={4}>
        {
          <NoMotionAlertSettings
            setting={settings?.motion_alert}
            deviceId={deviceId}
          />
        }
      </TabPanel>

      <TabPanel value={value} index={5}>
        <SetEmails emails={settings?.sos_emails || []} deviceId={deviceId} />
      </TabPanel>
    </Box>
  );
};

const SycnParams = ({ deviceId }: { deviceId: string }) => {
  const [loading, setLoading] = React.useState(false);

  const sync = async () => {
    setLoading(true);

    //const docId =

    const tasksRef = doc(db, "tasks", deviceId!);
    await setDoc(tasksRef, {
      device_id: deviceId,
      type: "sync_config",
      payload: {},
    });
    setLoading(false);
  };
  return (
    <LoadingButton loading={loading} variant="outlined" onClick={sync}>
      Sync Settings From Device
    </LoadingButton>
  );
};

export default Settings;
