
  // const [inputText, setInputText] = useState('');
  // const [encryptedText, setEncryptedText] = useState('');
  // const [decryptedText, setDecryptedText] = useState('');

  // const encrypt = () => {
  //   // Encryption logic
  //   const encrypted = inputText
      // .split('')
      // .map((char) => String.fromCharCode(char.charCodeAt(0) + 1))
      // .join('');

  //   setEncryptedText(encrypted);
  // };

  // const decrypt = () => {
  //   // Decryption logic
  //   const decrypted = encryptedText
      // ?.split('')
      // .map((char) => String.fromCharCode(char.charCodeAt(0) - 1))
      // .join('');

  //   setDecryptedText(decrypted);
  // };

class Auth {
    authenticated = false;
    login(data) {
     localStorage.setItem("token",data)
      if (data) {
        this.authenticated = true;
      } else {  
        this.authenticated = false;
      };
    };

    token() {
      const token = localStorage.getItem("token");
      return token;
    };

    logout() {
      localStorage.removeItem("token");
      this.authenticated = false;
    };
  
    isUserAuthenticated() {
      if (localStorage.getItem("token")) {
        return true;
      } else {
        return false;
      };
    };
  };
  // eslint-disable-next-line
  export default new Auth();
  