// @mui
import { styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, Typography } from "@mui/material";

import AccountPopover from "./dropdown";

import React from "react";
import { Icon } from "@iconify/react";
import logoImage from "../../resources/images/img_logo.png";
import "./header.css"
// ----------------------------------------------------------------------

const NAV_WIDTH = 0; //280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 64;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  //   ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: "none",
  [theme.breakpoints.up("xs")]: {
    width: `calc(100% - ${NAV_WIDTH}px)`,
  },
  position: "static",
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    // padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

type HeaderProps = {
  //
};

export default function Header(props: HeaderProps) {
  return (
    <StyledRoot>
      <StyledToolbar style={{backgroundColor: "#fff"}} className="navbarTop justify-between">
        <Box>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,

              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
            className="flex flex-row justify-start items-center space-x-3"
          >
            <img className="logoImage" src={logoImage} alt="Allwell Web App" />
            {/* <Icon icon={"mdi:robot-outline"} /> */}
            {/* <span>ALLWELL</span> */}
          </Typography>
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
          className="shrink-0"
        >
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
