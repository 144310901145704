import { Icon } from "@iconify/react";
import { fDateTime, fToNow } from "../../utils/formatTime";
import {
  Box,
  Card,
  CardHeader,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

import { Location } from "../../data.d";
import gpsIcon from "../../resources/images/ic_gps.png"
type Props = {
  list: Location[];
};

const LocationList = ({ list, ...other }: Props) => {
  return (
    <Card {...other}>
      <CardHeader
        title={
          <>
            Location Tracking
            <IconButton>
              <Icon icon={"jam:gps"} />
            </IconButton>
          </>
        }
      />

      <Stack
        spacing={3}
        sx={{ p: 3, pr: 0, height: "50rem", overflowY: "scroll" }}
      >
        {list.map((item, index) => (
          // <NewsItem key={news.id} news={news} />
          <Stack direction="row" alignItems="center" spacing={2} key={index}>
              <img src={gpsIcon} style={{marginRight: 0}} alt="gps icon" />

            {/* <Box width={48} height={48} sx={{ flexShrink: 0, margin: 0 }}> */}
              {/* <Icon icon={"mdi:location"} width={48} height={48} /> */}
            {/* </Box> */}

            <Box sx={{ minWidth: 240, flexGrow: 0 }}>
              <Typography style={{ wordWrap: "break-word", fontSize: 14 }}>
                {item?.address}
              </Typography>
              <Typography
                align="left"
                sx={{ pr: 3, flexShrink: 1, fontSize: 12, color: "text.secondary" }}
              >
                {fDateTime(item.timestamp * 1000)} (
                {fToNow(item.timestamp * 1000)})
              </Typography>
            </Box>
          </Stack>
        ))}
      </Stack>
    </Card>
  );
};

export default LocationList;
