import * as React from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { ContactNumber } from "@/data";
import { Icon } from "@iconify/react";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../services/firebase";
import { LoadingButton } from "@mui/lab";

const ContactItem = ({
  item,
  index,
  setItem,
}: {
  index: number;
  item: ContactNumber;
  setItem: (index: number, item: ContactNumber) => void;
}) => {
  return (
    <div
      key={index}
      className="set-contact-form"
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 16,
        alignItems: "center",
        border: "1px solid grey",
        borderRadius: 20,
      }}
    >
      <h4 style={{ marginLeft: 16 }}>A {index + 1}</h4>
      <TextField
        label="Phone Number"
        value={item.number}
        onChange={event =>
          setItem(index, { ...item, number: event.target.value })
        }
      />
      <div>
        <p style={{ marginBottom: 1, fontWeight: "bolder" }}>
          When there is an alarm
        </p>

        <FormControlLabel
          value="Call"
          control={
            <Checkbox
              checked={item?.accept_call}
              onChange={event =>
                setItem(index, { ...item, accept_call: event.target.checked })
              }
            />
          }
          label="Recieve Call"
          labelPlacement="end"
        />
        <FormControlLabel
          value="Recieve SMS"
          control={
            <Checkbox
              checked={item?.accept_sms}
              onChange={event =>
                setItem(index, { ...item, accept_sms: event.target.checked })
              }
            />
          }
          label="Recieve SMS"
          labelPlacement="end"
        />
      </div>
      <FormControlLabel
        control={
          <Switch
            checked={item?.enable}
            onChange={event =>
              setItem(index, { ...item, enable: event.target.checked })
            }
          />
        }
        label="Turn On"
      />
    </div>
  );
};

const SOSSettings = ({
  items,
  deviceId,
}: {
  items: ContactNumber[] | undefined;
  deviceId: string | undefined;
}) => {
  //const navigate = useNavigate();
  const [contactNumbers, setContactNumbers] = React.useState<ContactNumber[]>(
    items ? Object.values(items) : []
  );

  const [saving, setSaving] = React.useState(false);

  const addItem = (
    item: ContactNumber = {
      number: "",
      accept_call: false,
      accept_sms: false,
      enable: false,
    }
  ) => {
    setContactNumbers([...contactNumbers, item]);
  };

  const setItem = (index: number, item: ContactNumber) => {
    setContactNumbers([
      ...contactNumbers.slice(0, index),
      item,
      ...contactNumbers.slice(index + 1),
    ]);
  };

  const save = async () => {
    const payload = contactNumbers.filter(item => !!item.number);
    if (payload.length <= 0) {
      return;
    }
    setSaving(true);
    try {
      const tasksRef = doc(db, "tasks", deviceId!);
      await setDoc(tasksRef, {
        device_id: deviceId,
        type: "contact_numbers",
        payload,
      });
    } finally {
      setSaving(false);
    }
  };

  React.useEffect(() => {
    if (contactNumbers.length <= 0) {
      addItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <h4 style={{ marginTop: 0 }}>Set SOS Contacts for Device</h4>
      <>
        {contactNumbers?.map((item, i) => {
          return (
            <ContactItem item={item} index={i} key={i} setItem={setItem} />
          );
        })}
      </>
      {contactNumbers.length < 10 && (
        <Button size="large" fullWidth onClick={() => addItem()}>
          <Icon icon={"carbon:add-filled"} fontSize={32} />
        </Button>
      )}
      <Box textAlign={"right"}>
        <LoadingButton
          loading={saving}
          variant="outlined"
          size="large"
          onClick={() => save()}
        >
          Save
        </LoadingButton>
      </Box>
    </div>

    // <Button variant="outlined" color="error" onClick={()=>{navigate("/dashboard")}}>Go Back to Dashboard</Button>
  );
};

export default SOSSettings;
