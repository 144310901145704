import { Icon } from "@iconify/react";
import DashboardLayout from "../../layouts/dashboard";
import { Box, Button, Link, List, ListItem, Typography } from "@mui/material";

import { useParams } from "react-router-dom";
import BaseInfo from "./base-info";
import Health from "./health";
import Alerts from "./alerts";
import GpsTracking from "./gps-tracking";
import SwitchDevice from "./switch-device";
import { useContext, useState } from "react";
import AddDevice from "./add-device";
import { AuthContext } from "../../context/auth-context";
import Reports from "./reports";

const Navs = [
  { path: "base", name: "Device Info" },
  { path: "alert", name: "Alerts" },
  { path: "health", name: "Health / Activity Data" },
  { path: "gps", name: "GPS Location" },
  { path: "report", name: "Reports" },
];

const Dashboard = () => {
  const { deviceId } = useParams();

  const [addDevice, setAddDevice] = useState(false);

  return (
    <DashboardLayout>
      <Box className="flex flex-row h-full ">
        <Box className="shrink-0 border-r border-solid border-gray-300 w-[320px] flex flex-col">
          {deviceId && (
            <List className="pb-8 mb-16">
              {Navs.map((item, i) => {
                return (
                  <ListItem key={i}>
                    <Link
                      href={`/dashboard/${deviceId}/${item.path}`}
                      className="no-underline text-gray-900 hover:bg-gray-300 block w-full h-full p-1 px-4 rounded-md"
                    >
                      {item.name}
                    </Link>
                  </ListItem>
                );
              })}
            </List>
          )}

          <Box className="grow-0 border-t border-solid border-gray-300">
            <SwitchDevice handleAddDevice={() => setAddDevice(true)} />
          </Box>
        </Box>

        <Box className="h-full w-full overflow-y-auto">
          <MainContent handleAddDevice={() => setAddDevice(true)} />
        </Box>
      </Box>
      <AddDevice open={addDevice} handleClose={() => setAddDevice(false)} />
    </DashboardLayout>
  );
};

const MainContent = ({ handleAddDevice }: { handleAddDevice: () => void }) => {
  const { deviceId } = useParams();

  // if confirmed don't have any devices
  if (!deviceId) {
    return (
      <Box className="flex flex-col items-center justify-center p-6">
        <Typography>
          You don't have any device, you should bind one before can view data
        </Typography>
        <Button
          variant="outlined"
          className="my-8 py-4 w-40"
          onClick={handleAddDevice}
        >
          <Icon icon={"mdi:add"} />
          Add Device
        </Button>
      </Box>
    );
  } else {
    return (
      <Box className="p-6">
        <TabContent />
      </Box>
    );
  }
};

const TabContent = () => {
  const { tab = "base" } = useParams();
  const { user } = useContext(AuthContext);

  if (tab === "base") {
    return <BaseInfo user={user}></BaseInfo>;
  } else if (tab === "gps") {
    return <GpsTracking />;
  } else if (tab === "health") {
    return <Health user={user}></Health>;
  } else if (tab === "alert") {
    return <Alerts />;
  } else if (tab === "report") {
    return <Reports user={user} />;
  } else {
    return <></>;
  }
};

export default Dashboard;
