import { db } from "../../services/firebase";
import { useAuth } from "../../context/auth-context";
import { DeviceInterface } from "../../data";
import { Icon, InlineIcon } from "@iconify/react";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  Unsubscribe,
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import CircularProgress from '@mui/material/CircularProgress';


const SwitchDevice = ({ handleAddDevice }: { handleAddDevice: () => void }) => {
  const { deviceId } = useParams();
  const navigate = useNavigate();

  const [allDevices, setAllDevices] = useState<DeviceInterface[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>('');

  const { user } = useAuth();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // console.info('logged in user', user?.email, selectedDeviceId);
    let userDocUnSub: Unsubscribe;
    if (user && user?.email) {
      const userEmail = user?.email;
      setLoading(true);
      const userDocRef = query(collection(db, `users`), where("email", "==", userEmail));
      userDocUnSub = onSnapshot(userDocRef, (snapshot) => {
        setLoading(false);
        snapshot.docChanges().forEach((change) => {
          // console.log("users data: ", change.type, change.doc.data());
          const devices = change.doc.data().devices;
          setAllDevices(devices || []);
          if (!selectedDeviceId && devices?.length) {
            setSelectedDeviceId(devices[0].id);
            navigate(`/dashboard/${devices[0].id}`);
            // !selectedDeviceId && navigate(`/dashboard/${devices[0].id}`);
          }
        });
      });
    }
    return () => {
      userDocUnSub && userDocUnSub();
    }
  }, [user]);

  !selectedDeviceId && deviceId && setSelectedDeviceId(deviceId);

  const onSwitchDevice = (event: SelectChangeEvent) => {
    const selectedId = event.target.value;
    setSelectedDeviceId(selectedId);
    navigate(`/dashboard/${selectedId}`);
  };
  if (loading) {
    return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="p-6">
    <CircularProgress size={20} />
  </Box>;
  }

  return (
    <Box
      sx={{ flexGrow: 1 }}
      className="shrink-0 items-center w-full pb-6 pl-8 pr-8"
    >
      <Box className="flex flex-row items-center justify-between">
        <Typography className="py-4">Switch Device:&nbsp;</Typography>
        <Box className="">
          <IconButton
            size="small"
            onClick={() => {
              navigate(`/settings/${selectedDeviceId}`);
            }}
          >
            <InlineIcon icon={"simple-line-icons:settings"} />
          </IconButton>
        </Box>
      </Box>

      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedDeviceId}
        sx={{ width: 250 }}
        onChange={onSwitchDevice}
        className="bg-white text-gray-800"
        size="small"
        color="primary"
      >
        {allDevices && allDevices.map((d, i) => (
          <MenuItem value={d.id} key={i} className="text-gray-800">
            {d?.name || d.id}
          </MenuItem>
        ))}
      </Select>
      <Box className="flex justify-center py-2">
        <Button variant="outlined" fullWidth onClick={handleAddDevice}>
          <Icon icon={"mdi:add"} /> Add Device
        </Button>
      </Box>
    </Box>
  );
};

export default SwitchDevice;
