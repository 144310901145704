import { useEffect, useState } from "react";
import { Alert } from "../../data";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useParams } from "react-router";
import { db } from "../../services/firebase";
import { Box } from "@mui/material";

import AlertList from "./alert-list";

const Alerts = () => {
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [alertsFilter, setAlertsFilter] = useState<string[]>([]);
  const { deviceId } = useParams();

  useEffect(() => {
    if (!deviceId) return;
    setAlerts([]);

    const alertsQuery = query(
      collection(db, `/alert/${deviceId}/records`),
      orderBy("timestamp", "desc"),
      //where("type", "in", ["sos", "fall_down"]),
      limit(100),
      alertsFilter.length > 0 ? where("type", "in", alertsFilter) : limit(100)
    );

    const unsubAlerts = onSnapshot(alertsQuery, snapshot => {
      const alertsData: Alert[] = [];
      snapshot.forEach(doc => {
        alertsData.push({ id: doc.id, ...doc.data() } as Alert);
      });
      setAlerts(alertsData);
    });

    return () => {
      unsubAlerts();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId, alertsFilter]);

  return (
    <Box className="w-full ">
      <AlertList list={alerts} setAlertsFilter={setAlertsFilter} />
    </Box>
  );
};

export default Alerts;
