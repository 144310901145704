import React, { useContext, useState } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  MenuItem,
  IconButton,
  Popover,
  Avatar,
} from "@mui/material";

import { AuthContext } from "../../context/auth-context";
import { useNavigate } from "react-router";
import { Icon } from "@iconify/react";
import Auth from "../../auth/Auth";

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const { signOut, user } = useContext(AuthContext);

  const navigate = useNavigate();

  const logout = () => {
    Auth.logout();
    signOut && signOut();
    // handleClose();
    navigate("/login");
  };

  // const openSettingsPage = () => {
  //   navigate("/settings");
  // }

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open
            ? {
                "&:before": {
                  zIndex: 1,
                  content: "''",
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                  position: "absolute",
                  bgcolor: theme => alpha(theme.palette.grey[900], 0.8),
                },
              }
            : {}),
        }}
      >
        <Avatar>
          <Icon icon={"mdi:user"} />
        </Avatar>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user?.email}
          </Typography>
        </Box>
        {/* <MenuItem onClick={()=>openSettingsPage()} sx={{ m: 1 }}>
          Settings
        </MenuItem> */}

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={()=>logout()} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
