import { useState, useContext, useEffect } from "react";

// @mui
import {
  // Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  Box,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useNavigate, Link } from "react-router-dom";

// components
import { AuthContext } from "../../context/auth-context";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
// ----------------------------------------------------------------------

export default function SignupForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [err, setError] = useState("");
  const { signUp } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [check, setCheck] = useState(false);
  const [commonState, setCommonState] = useState({
    btnLoader: false,
  });

  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const pass = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

  const handleClick = async () => {
    if (!email) {
      setError(true);
    } else if (!emailRegex.test(email)) {
      setError(true);
    } else if (!password) {
      setError(true);
    } else if (!pass.test(password)) {
      setError(true);
    } else if (check === false) {
      setError(true);
    } else {
      setCommonState({ btnLoader: true });
      const response = await signUp(email, password);
      if (response?.operationType === `signIn`) {
        navigate("/login");
        setCommonState({ btnLoader: false });
      } else {
        toast.error("This email address is already exist");
        setCommonState({ btnLoader: false });
      }
      // setError("");
      // const error = await signUp(email, password);
      // if (error) {
      //   setError(error);
      // } else {
      //   navigate("/login");
      // }
    }
  };

  useEffect(() => {
    localStorage.setItem("signupEmail", email);
    localStorage.setItem("signupPassword", password);
  }, [email, password]);

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Email address"
          value={email}
          error={
            err && !email
              ? true
              : !emailRegex.test(email) && email
              ? true
              : false
          }
          onChange={(e) => setEmail(e.target.value)}
        />
        {emailRegex.test(email) === false && !email ? (
          ""
        ) : emailRegex.test(email) === false && email ? (
          <Box sx={{ color: "error.main" }}>Invalid email address</Box>
        ) : (
          ""
        )}

        <TextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          aria-errormessage="adb"
          error={
            err && !password
              ? true
              : !pass.test(password) && password
              ? true
              : false
          }
          helperText={err}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Icon
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {pass.test(password) === false && !password ? (
          ""
        ) : pass.test(password) === false && password ? (
          <Box sx={{ color: "error.main" }}>Please enter strong password</Box>
        ) : (
          ""
        )}
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <FormControlLabel label="Remember Me" control={
          <Checkbox
          name="remember"
          label="Remember me"
          id="remeber_me"
          sx={
            err
              ? {
                  color: "red",
                }
              : {}
          }
          value={check}
          onChange={(e) => setCheck(e.target.checked)}
        />
        } />
        
        <Link
          to="/forget-password"
          // variant="subtitle2" underline="hover"
        >
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        loading={commonState.btnLoader}
        fullWidth
        size="large"
        type="submit"
        variant="outlined"
        onClick={handleClick}
      >
        Sign up
      </LoadingButton>
    </>
  );
}
