import { useEffect, useState } from "react";
import { HealthData } from "../../data";
import {
  collection,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useParams } from "react-router";
import { db } from "../../services/firebase";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import HelathLineChart from "./line-chart";
import { fToNow } from "../../utils/formatTime";
import { Icon } from "@iconify/react";
import { User } from "firebase/auth";

interface Props {
  user: User | undefined;
}

const Health = ({ user }: Props) => {
  const [hrs, setHrs] = useState<HealthData[]>([]);
  const [steps, setSteps] = useState<HealthData[]>([]);
  const { deviceId } = useParams();
  const [supportHealthData, setSupportsHealthData] = useState(false);
  useEffect(() => {
    if (!deviceId) return;

    setHrs([]);
    setSteps([]);

    // get device model
    const usersCollection = collection(db, "users");
    let userEmail: String = "";
    if (user?.email) {
      userEmail = user.email;
    }
    const q = query(usersCollection, where("email", "==", userEmail));
    getDocs(q).then((data) => {
      data.docChanges().forEach((item) => {
        const userDevices = item.doc.data().devices;
        userDevices.map((device: any) => {
          if (device.id === deviceId) {
            if (device.model === "AW04") {
              setSupportsHealthData(false);
            }
            if (device.model === "AW05") {
              setSupportsHealthData(true);
            }
          }
        });
      });
    });

    const hrQuery = query(
      collection(db, `/health/${deviceId}/heart-rate`),
      orderBy("timestamp", "desc"),
      limit(20)
    );

    const setpsQuery = query(
      collection(db, `/health/${deviceId}/steps`),
      orderBy("timestamp", "desc"),
      limit(20)
    );

    const unsubHR = onSnapshot(hrQuery, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          setHrs((old) => [
            {
              ...change.doc.data(),
            } as HealthData,
            ...old,
          ]);
        }
      });
    });

    const unsubSteps = onSnapshot(setpsQuery, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          setSteps((old) => [
            {
              ...change.doc.data(),
            } as HealthData,
            ...old,
          ]);
        }
      });
    });

    return () => {
      unsubHR();
      unsubSteps();
    };
  }, [deviceId, user?.email]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6} md={4}>
        <Card>
          {supportHealthData ? (
            <CardContent>
              <Typography variant="h6">Heart Rate</Typography>
              <Divider sx={{ borderStyle: "dashed", marginY: 2 }} />
              <Stack spacing={1} direction="row" alignItems="center">
                <Icon icon={"mdi:heart"} color="red" fontSize={40} />
                <Typography variant="h3">
                  {hrs.length > 0 ? hrs.at(-1)?.value : "-"}
                </Typography>
                <Typography variant="caption">
                  {hrs.length > 0 ? fToNow(hrs.at(-1)!.timestamp * 1000) : ""}
                </Typography>
              </Stack>

              <Stack>
                <HelathLineChart values={hrs} />
              </Stack>
            </CardContent>
          ) : (
            <CardContent>
              <Typography variant="h6">Heart Rate</Typography>
              <Divider sx={{ borderStyle: "dashed", marginY: 2 }} />
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="p-6">
                <Icon
                  style={{ fontSize: 50, color: "#f44336" }}
                  icon={"ph:warning"}
                  />
              </Box>
              <Typography
                fontSize={16}
                textAlign={"center"}
                fontWeight={"bold"}
              >
                This device is Pendant and doesn't support health data.
              </Typography>
              <Typography variant="body1" textAlign={"center"}>
                To view health data, please use a watch device.
              </Typography>
            </CardContent>
          )}
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Card>
          {supportHealthData ? (
            <CardContent>
              <Typography variant="h6">Steps</Typography>
              <Divider sx={{ borderStyle: "dashed", marginY: 2 }} />
              <Stack spacing={1} direction="row" alignItems="center">
                <Icon icon={"mdi:walk"} color="purple" fontSize={40} />
                <Typography variant="h3">
                  {steps.length > 0 ? steps.at(-1)?.value : "-"}
                </Typography>
                <Typography variant="caption">
                  {steps.length > 0
                    ? fToNow(steps.at(-1)!.timestamp * 1000)
                    : ""}
                </Typography>
              </Stack>

              <Stack>
                <HelathLineChart values={steps} />
              </Stack>
            </CardContent>
          ) : (
            <CardContent>
              <Typography variant="h6">Steps</Typography>
              <Divider sx={{ borderStyle: "dashed", marginY: 2 }} />
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="p-6">
                <Icon
                  style={{ fontSize: 50, color: "#f44336" }}
                  icon={"ph:warning"}
                  />
              </Box>
              <Typography
                fontSize={16}
                textAlign={"center"}
                fontWeight={"bold"}
              >
                This device is Pendant and doesn't support health data.
              </Typography>
              <Typography variant="body1" textAlign={"center"}>
                To view health data, please use a watch device.
              </Typography>
            </CardContent>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default Health;
