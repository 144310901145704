import React, { PropsWithChildren, useContext, useEffect } from "react";
import { styled } from "@mui/material";
import Header from "./dashboard/header";

import { AuthContext } from "../context/auth-context";
import { useNavigate } from "react-router";

const StyledRoot = styled("div")({
  display: "flex",
  height: "100vh",
  overflow: "hidden",
  flexDirection: "column",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "hidden",
  height: "100%",
  color: theme.palette.text.primary,
}));

const DashboardLayout = ({ children }: PropsWithChildren) => {
  const { isSignedIn, loading } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isSignedIn && !loading) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn, loading]);

  return (
    <StyledRoot>
      <Header />
      <Main>{children}</Main>
    </StyledRoot>
  );
};

export default DashboardLayout;
