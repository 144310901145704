import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Polygon,
  DrawingManager,
  Libraries,
  Autocomplete,
} from "@react-google-maps/api";
import { Box, Button } from "@mui/material";

type PProps = {
  zoom: number;
  path?: { lat: number; lng: number }[];
  setPath: (path: { lat: number; lng: number }[]) => void;
  handleClose: () => void;
};

const mapLibraries = ["drawing", "places"];

function PolygonArea({ setPath, path = [], zoom = 15, handleClose }: PProps) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API as string,
    libraries: mapLibraries as Libraries,
    version: "weekly",
  });
  const [map, setMap] = useState<google.maps.Map>();
  const [tmpPath, setTmpPath] = useState(path);
  // get user's geo
  useEffect(() => {
    if (path.length > 0) return;
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        console.log("set user location center");
        map?.setCenter(pos);
        map?.setZoom(12);
      },
      () => {
        console.log("set default center");
        map?.setCenter({ lat: 35.0, lng: -98.0 });
        map?.setZoom(5);
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  const polygonRef = useRef<google.maps.Polygon>();
  const listenersRef = useRef<google.maps.MapsEventListener[]>([]);

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setTmpPath(nextPath);
    }
  }, [setTmpPath]);

  // Bind refs to current Polygon and listeners
  const onPolygonLoad = useCallback(
    (polygon: google.maps.Polygon) => {
      polygonRef.current = polygon;
      const _path = polygon.getPath();
      listenersRef.current.push(
        _path.addListener("set_at", onEdit),
        _path.addListener("insert_at", onEdit),
        _path.addListener("remove_at", onEdit)
      );
      if (path.length) {
        const map = polygon.getMap();
        const bounds = new google.maps.LatLngBounds();
        polygon.getPath().forEach(latlng => {
          bounds.extend(latlng);
        });

        if (!bounds.isEmpty()) {
          map?.fitBounds(bounds);
        } else {
          console.log("empty bounds");
        }
      }
    },
    [onEdit]
  );

  // Clean up refs
  const onPolygonUnmount = useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove());
    polygonRef.current = undefined;
  }, []);

  //=========search box
  const [searchBox, setSearchBox] = useState<google.maps.places.Autocomplete>();
  // const [searchResult, setSearchResult] = useState<any[]>([]);
  const searchBoxOnload = (ref: google.maps.places.Autocomplete) => {
    setSearchBox(ref);
    // setSearchResult(ref as any);
  };
  const onPlaceChanged = () => {
    if (searchBox != null) {
      const place = searchBox.getPlace();
      if (place.geometry?.viewport) {
        map!.fitBounds(place.geometry.viewport);
      } else if (place.geometry?.location) {
        map!.setCenter(place.geometry.location);
        map!.setZoom(15);
      }
    }
  };

  return isLoaded ? (
    <Box>
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "400px" }}
        zoom={zoom}
        center={path[0]}
        onLoad={map => setMap(map)}
        options={{
          streetViewControl: false,
          fullscreenControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM,
          },
        }}
      >
        <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={searchBoxOnload}>
          <input
            type="text"
            placeholder="Where you wanna pinpoint a geo-fence?"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          />
        </Autocomplete>
        {tmpPath.length > 3 && (
          <Polygon
            // Make the Polygon editable / draggable
            editable
            draggable
            path={tmpPath}
            // Event used when manipulating and adding points
            onMouseUp={onEdit}
            // Event used when dragging the whole Polygon
            onDragEnd={onEdit}
            onLoad={onPolygonLoad}
            onUnmount={onPolygonUnmount}
          />
        )}
        {tmpPath.length <= 3 && <MyDrawingManager setPath={setTmpPath} />}
      </GoogleMap>
      <Box sx={{ paddingTop: 2 }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={() => {
            setTmpPath([]);
          }}
        >
          Clear
        </Button>
        <Button
          onClick={() => {
            if (tmpPath.length) {
              setPath(tmpPath);
            }

            handleClose();
          }}
        >
          Confirm
        </Button>
      </Box>
    </Box>
  ) : (
    <>Loading...</>
  );
}

const MyDrawingManager = ({
  setPath,
}: {
  setPath: (
    value: {
      lat: number;
      lng: number;
    }[]
  ) => void;
}) => {
  const onRectangleComplete = (rectangle: google.maps.Rectangle) => {
    const bounds = rectangle.getBounds();
    const NE = bounds!.getNorthEast();
    const SW = bounds!.getSouthWest();

    setPath([
      { lat: NE.lat(), lng: NE.lng() },
      {
        lat: SW.lat(),
        lng: NE.lng(),
      },
      {
        lat: SW.lat(),
        lng: SW.lng(),
      },
      {
        lat: NE.lat(),
        lng: SW.lng(),
      },
    ]);
    rectangle.setMap(null);
  };

  return (
    <DrawingManager
      options={{
        drawingControl: true,
        drawingControlOptions: {
          drawingModes: [google.maps.drawing.OverlayType.RECTANGLE],
        },
      }}
      drawingMode={google.maps.drawing.OverlayType.RECTANGLE}
      onRectangleComplete={onRectangleComplete}
    />
  );
};

export default PolygonArea;
