import React from "react";

import { Navigate } from "react-router-dom";

import { useAuth } from "../context/auth-context";

 const PrivateRoute = ({ children }) => {
  const {isSignedIn, loading} = useAuth()
  if (!isSignedIn && !loading) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return children;
};

export default PrivateRoute;
