import { fToNow } from "../../utils/formatTime";
import { Chart as ChartJS, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(...registerables);

type Props = {
  values: { timestamp: number; value: number }[];
};

const HelathLineChart = ({ values }: Props) => {
  return (
    <Line
      options={{
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            display: false,
          },
          y: {
            display: true,
          },
        },
      }}
      data={{
        labels: values?.map(v => fToNow(v.timestamp * 1000)),
        datasets: [
          {
            label: "",
            data: values?.map(v => v.value),
          },
        ],
      }}
    />
  );
};

export default HelathLineChart;
