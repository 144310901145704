// import { Helmet } from "react-helmet-async";
// @mui
import { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router";
import { styled } from "@mui/material/styles";
import {
  // Link,
  Container,
  Typography,
  Divider,
  Stack,
  Button,
} from "@mui/material";

import { useAuth } from "../../context/auth-context";

import { Icon } from "@iconify/react";

import LoginForm from "./form";

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const LoginPage = () => {
  const { isSignedIn, loading } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (isSignedIn) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn, loading]);
  return (
    <>
      {/* <Helmet>
        <title> Login | ALLWELL </title>
      </Helmet> */}
      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Sign in to Allwell
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
              Don’t have an account? {""}
              <Link
                to="/signup"
                // variant="subtitle2"
              >
                Get started
              </Link>
            </Typography>

            <Stack direction="row" spacing={2}>
              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Icon
                  icon="eva:google-fill"
                  color="#DF3E30"
                  width={22}
                  height={22}
                />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Icon
                  icon={"eva:facebook-fill"}
                  color="#1877F2"
                  width={22}
                  height={22}
                />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Icon
                  icon="eva:twitter-fill"
                  color="#1C9CEA"
                  width={22}
                  height={22}
                />
              </Button>
            </Stack>

            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                OR
              </Typography>
            </Divider>

            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
};

export default LoginPage;
